.container {
    display: flex;
    height: 100vh;
  }
  
  .left-block {
    background-color: #0E0B26;
    flex: 1;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .left-block .Logo {
    position: absolute;
    top: 2rem;
    left: 2rem;
    display: flex;
    align-items: flex-start;
    width: "10rem";
    height: auto;
    object-fit: contain;
  }
  
  .left-block .centered {
    width: auto;
    height: "15rem";
    object-fit: cover;
  }
  
  .right-block {
    background-color: #FBFAF6;
    flex: 1;
    height: 100%;
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: left;
  }

  .right-block .store-name {
    color: black;
    font-size: 1.3rem;
    font-weight: 400;
    margin-top: 2rem;
  }

  /* .store-name-background {
    background-color: #fefed9; 
  } */
  
  .right-block .title,
  .right-block .description {
    display: block;
  }

  .right-block .right-content {
    margin: 0 8rem;
  }


  
  .right-block .title {
    color: black;
    margin: 0;
    margin-bottom: 2rem;
    font-size: 3rem;
    font-weight: bold;
    text-align: center;
  }
  
  .right-block .description {
    margin: 0.5rem 0;
    margin-bottom: 1rem;
    font-size: 1.1rem;
  }
  
  
  .right-block label {
    color: black;
    margin: 0;
  }
  
  .right-block input {
    padding: 0.5rem;
    border-radius: 2rem;
    border: 2px solid lightgray;
    width: 20rem;
    height: 2rem;
    margin-bottom: 1rem;
  }
  
  .right-block button {
    background-color: yellow;
    border-radius: 2rem;
    width: 20rem;
    height: 3rem;
    margin-top: 2rem;
    border: none;
  }

